<template>
    <div class="platform-dashboard-pages" v-if="!loading">
        <div class="platform-dashboard-pages__filter" :class="{'disabled-filter': !businessDashboardCountryContainer}">
            <BusinessDashboardCountriesFilters
                :dashboardCountriesFiltersState="dashboardCountriesFiltersState"
                @onApplyFilters="fetchCountriesData"/>
        </div>

        <div v-if="businessDashboardCountryContainer" class="platform-dashboard-pages__content">
            <DashboardCommonHeader
                class="mb-5"
                :title="$t('Countries')"
                :description="$t('Click on any of the country from the list for more details on it')"
                :count="businessDashboardCountryContainer.totalScore.count"
                :add-button-text="$t('Manage Countries')"
                title-icon="/media/buying/icons/map-black-icon.svg"
                @onSortChange="handleSortChange"
                @onClickAdd="handleClickAdd"
            />

            <DashboardStatsTable
                :score-title="$t('Company Score')"
                :score-text="$t('Based on all your Country/filter applied')"
                :total-score="businessDashboardCountryContainer.totalScore"
            >
                <template #content>
                    <div class="stats-collapse">
                        <template v-if="businessDashboardCountryContainer.containerData.length">
                            <transition-group name="group-transition">
                                <div :key="item.item.name" v-for="item of businessDashboardCountryContainer.containerData">
                                    <DashboardStatsCollapseItem
                                        :containerItem="item"
                                        :filterData="dashboardCountriesFiltersState"
                                        :pageType="BusinessPageTypes.COUNTRY"
                                    />
                                </div>
                            </transition-group>
                        </template>

                        <div class="section-card section-card__border" v-else>
                            <DashboardEmptyState
                                icon="/media/buying/icons/empty-state-country.svg"
                                :title="$t('No Country Found')"
                                :text="$t('Your company don\'t have any data for the countries selected. Create a feedback session to start reviewing and get insights into your banks performance.')"
                                :button-text="$t('Add Country')"
                                button-link="/business/settings/user-settings/overview"
                                button-icon="/media/buying/icons/plus.svg"
                                class-name="mb-10"
                            />
                        </div>
                    </div>
                </template>
            </DashboardStatsTable>
        </div>
        <CustomLoader v-else />
    </div>
</template>

<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { diagLog } from "@/core/helpers/GlobalHelper";
import store from "@/store";
import DashboardCommonHeader from "@/buying-teams/shared-components/dashboard/DashboardCommonHeader";
import DashboardStatsTable from "@/buying-teams/shared-components/dashboard/DashboardStatsTable";
import DashboardStatsCollapseItem from "@/buying-teams/shared-components/dashboard/DashboardStatsCollapseItem";
import DashboardEmptyState from "@/buying-teams/shared-components/dashboard/DashboardEmptyState";
import BusinessDashboardCountriesFilters from "@/buying-teams/pages/business/dashboard/components/BusinessDashboardCountriesFilters";
import {
    BusinessDashboardCountriesContainer
} from "@/store/models/business/dashboard/level2/BusinessDashboardCountriesContainer";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { BusinessPageTypes } from "@/store/enums/business/BusinessPageTypes";
import BusinessFiltersTypeEnum from "@/store/enums/business/BusinessFiltersTypeEnum";

export default {
    name: "Countries",
    components: {
        CustomLoader,
        DashboardEmptyState,
        BusinessDashboardCountriesFilters,
        DashboardStatsCollapseItem,
        DashboardStatsTable,
        DashboardCommonHeader
    },
    data() {
        return {
            loading: true,
            businessDashboardCountryContainer: null,
            BusinessPageTypes
        };
    },
    computed: {
        businessData() {
            return store.getters.business;
        },
        dashboardCountriesFiltersState() {
            return store.getters.getBusinessFiltersState.dashboardCountries
        },
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.businessData.name,
            logo: this.businessData.icon_path
        });
    },
    async mounted() {
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.DASHBOARD_COUNTRIES);
        this.loading = false;
        await this.fetchCountriesData(this.dashboardCountriesFiltersState);
    },
    methods: {
        handleSortChange(sorting) {
            this.businessDashboardCountryContainer.sort(sorting);
        },
        handleClickAdd() {
            this.$router.push("/business/settings/user-settings/overview");
        },
        async fetchCountriesData(filter = null) {
            try {
                diagLog("filter ", filter);
                this.businessDashboardCountryContainer = null;
                let res = await store.dispatch("fetchBusinessDashboardDataLevelTwo", {filter, type: BusinessPageTypes.COUNTRY});
                this.businessDashboardCountryContainer = new BusinessDashboardCountriesContainer(res);
                diagLog("fetchCountriesData DATA", res);
                diagLog("fetchCountriesData MODEL", this.businessDashboardCountryContainer);
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>
