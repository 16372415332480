<template>
    <div class="dashboard-common-filters" v-if="isReady">
        <div class="dashboard-common-filters__top">
            <div class="dashboard-common-filters__top--title">
                <span>{{ $t("Filters") }}</span>
                <a v-if="showFilterBadges" @click.prevent="setFiltersDefaultState" href="">{{ $t("Clear All") }}</a>
            </div>

            <FiltersScoreImportanceInfo />
        </div>

        <div class="dashboard-common-filters__card section-card">
            <div class="dashboard-common-filters__item">
                <div class="dashboard-common-filters__item--label">{{ $t('Countries') }}</div>
                <SelectBoxWithModal
                    v-model="filterData.countries"
                    self-value
                    multiple
                    show-badge
                    :options="filterAllData.all_countries"
                    :all-selected-text="$t(filterAllSelectedText(BusinessFiltersSelectTypeEnum.COUNTRIES))"
                    :custom-selection-text="$t(filterCustomSelectionText(BusinessFiltersSelectTypeEnum.COUNTRIES))"
                    @onTouch="isFilterTouched = true"
                />
                <div v-if="isFilterTouched && !filterData.countries.length" class="text-danger mt-1">
                    {{ $t('Please select country') }}
                </div>
            </div>
            <div class="dashboard-common-filters__item">
                <div class="dashboard-common-filters__item--label">{{ $t("Banks") }}</div>
                <SelectBoxWithModal
                    v-model="filterData.bank_ids"
                    multiple
                    show-badge
                    :options="filterAllData.all_bank_ids"
                    :all-selected-text="$t(filterAllSelectedText(BusinessFiltersSelectTypeEnum.BANKS))"
                    :custom-selection-text="$t(filterCustomSelectionText(BusinessFiltersSelectTypeEnum.BANKS))"
                    @onTouch="isFilterTouched = true"
                />
                <div v-if="isFilterTouched && !filterData.bank_ids.length" class="text-danger mt-1">
                    {{ $t('Please select bank') }}
                </div>
            </div>
            <div class="dashboard-common-filters__item">
                <div class="dashboard-common-filters__item--label">{{ $t("Product Areas") }}</div>
                <SelectBoxWithModal
                    v-model="filterData.product_areas"
                    self-value
                    multiple
                    show-badge
                    :options="filterAllData.all_product_areas"
                    :all-selected-text="$t(filterAllSelectedText(BusinessFiltersSelectTypeEnum.PRODUCT_AREAS))"
                    :custom-selection-text="$t(filterCustomSelectionText(BusinessFiltersSelectTypeEnum.PRODUCT_AREAS))"
                    @onTouch="isFilterTouched = true"
                />
                <div v-if="isFilterTouched && !filterData.product_areas.length" class="text-danger mt-1">
                    {{ $t('Please select product area') }}
                </div>
            </div>
            <div class="dashboard-common-filters__item">
                <div class="dashboard-common-filters__item--label">{{ $t("Time Period") }}</div>
                <DateRangePicker
                    @onTouch="isFilterTouched = true"
                    :all-time-selection-value="businessBaseFilterDefaultState.time_period"
                    v-model="filterData.time_period"
                />
            </div>
            <div class="dashboard-common-filters__item apply-section">
                <button @click="handleApplyFilters" class="main-btn btn apply-section__btn" :disabled="!isFilterValid">
                    {{ $t("Apply") }}
                    <span
                        v-show="isFilterTouched && !isFilterApplied"
                        class="apply-section__btn--badge"
                    >
                        <BusinessFilterAppliedBadges
                            :filterData="filterData"
                            :allData="filterAllData"
                            :forceShow="showFilterBadges === 0"
                            @getSize="handleGetSize"
                        />
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import SelectBoxWithModal from "@/buying-teams/shared-components/inputs/SelectBoxWithModal";
import FiltersScoreImportanceInfo from "@/buying-teams/shared-components/utils/FiltersScoreImportanceInfo";
import DateRangePicker from "@/buying-teams/shared-components/date-time/DateRangePicker";
import store from "@/store";
import BusinessFilterAppliedBadges from "@/buying-teams/shared-components/filter/BusinessFilterAppliedBadges";
import { globalFilterValidator } from "@/core/helpers/GlobalHelper";
import { BusinessFiltersSelectTypeEnum } from "@/store/enums/business/BusinessFiltersSelectTypeEnum";
import { filterAllSelectedText, filterCustomSelectionText } from "@/core/helpers/FiltersHelper";

export default {
    name: "BusinessDashboardCountriesFilters",
    components: {
        FiltersScoreImportanceInfo,
        BusinessFilterAppliedBadges,
        DateRangePicker,
        SelectBoxWithModal,
    },
    emits: ['onApplyFilters'],
    props: {
        dashboardCountriesFiltersState: Object,
    },
    data() {
        return {
            isFilterTouched: false,
            showFilterBadges: false,
            isReady: false,
            filterData: {
                countries: [],
                product_areas: [],
                bank_ids: [],
                time_period: {
                    start_date: "",
                    end_date: ""
                }
            },
            isFilterApplied: true,
            BusinessFiltersSelectTypeEnum,
            filterAllSelectedText,
            filterCustomSelectionText
        };
    },
    computed: {
        filterAllData() {
            return {
                all_countries: store.getters.businessCountries,
                all_product_areas: store.getters.businessProductAreas,
                all_bank_ids: store.getters.businessBanks,
            }
        },
        businessBaseFilterDefaultState() {
            return store.getters.businessBaseFilterDefaultState;
        },
        isFilterValid() {
            return globalFilterValidator(this.filterData);
        }
    },
    mounted() {
        if (this.dashboardCountriesFiltersState) {
            this.filterData = this.dashboardCountriesFiltersState;
        }
        this.isReady = true;
    },
    methods: {
        handleApplyFilters() {
            this.isFilterApplied = true;
            this.$emit('onApplyFilters', this.filterData);
        },
        setFiltersDefaultState() {
            this.filterData = {...this.businessBaseFilterDefaultState};
            this.isFilterApplied = false;
            this.isFilterTouched = false;
        },
        handleGetSize(touchedCount) {
            this.isFilterApplied = false;
            this.showFilterBadges = touchedCount;
        }
    }
};
</script>
